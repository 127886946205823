.container {
  min-height: calc(100vh - 50px);
  max-width: 100vw;
  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 8px;
  overflow: auto;
}

.title {
  width: 100%;
  background-color: rgb(234, 218, 186);
  text-align: center;
  font-size: 1.3rem;
  display: block;
  height: 2.5rem;
  padding-top: 4px;
}

.contents {
  display: flex;
  flex-direction: row;

  .selectionPanel {
    // width: 250px;
    width: fit-content;
    // background-color: pink;
    margin-top: 10px;
    height: calc(100vh - 115px);
    overflow: auto;
  }
}

.displayPanel {
  flex: 1;
  min-width: 800px;
}
