.container {
  min-height: calc(100vh - 50px);
  max-width: 100vw;
  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 8px;
  overflow: auto;
}

.title {
  width: 100%;
  background-color: rgb(241, 223, 248);
  text-align: center;
  font-size: 1.3rem;
  display: block;
  height: 2.5rem;
  padding-top: 4px;
  position: relative;

  .goBackButton {
    position: absolute;
    left: 0px;
  }
}

.contents {
  display: flex;
  flex-direction: row;

  .selectionPanel {
    width: 300px;
    // background-color: pink;
    margin-top: 10px;
    height: calc(100% - 100px);
    overflow: auto;
  }
}

.displayPanel {
  flex: 1;
  min-width: 800px;
}

.dataTable {
  background-color: rgb(192, 230, 255);
}

.tableHeaderRow {
  width: 100%;
  // position: relative;

  .buttonContainer {
    // position: absolute;
    // top: 10px;
    // left: 15px;
    // z-index: 5;
    // width: 100%;
    height: 50px;
    display: flex;
    padding-left: 14px;
    justify-content: space-between;
    // align-items: flex-start;
    .exportButton,
    .clearFilterButton,
    .syncButton,
    .summaryButton {
      border-radius: 4px;
      margin-right: 8px;
      border: 0;
    }

    .infoText {
      margin-top: 14px;
      margin-right: 20px;
      // width: 220px;
      color: cornflowerblue;
    }
  }
}

.syncButton {
  border-radius: 4px;
  margin-right: 8px;
  border: 0;
}

.popoverTable {
  table,
  td,
  th {
    color: darkslategray;
    border: 1px solid gray;
    text-align: right;
    padding: 4px;
    padding-right: 10px;
  }
  td {
    width: 100px;
    padding-right: 20px;
  }
}
