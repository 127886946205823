.StandardMessagesHeader span {
  margin-right: 30px;
}
.MessageContainer h4,
.MessageContainer p {
  margin-left: 15px;
}
.MessageTitle {
  padding: 5px 10px;
  color: darkgreen;
  border: 1px solid lightblue;
  background-color: lightgoldenrodyellow;
}

.MessageContainer .QuestionTableTitle {
  margin-left: 33px;
  display: flex;
  justify-content: space-between;
}

.QuestionTableTitle span {
  margin-right: 8px;
}
.QuestionsTable {
  margin-bottom: 30px;
  margin-left: 30px;
  width: 95%;
  border: 1px solid black;
  /* background-color: green; */
}

.QuestionsTable td {
  border: 1px solid lightgray;
  padding: 5px 8px;
  /* background-color: red; */
}

.SubQuestionTitleColumn {
  color: green;
  width: 220px;
}

/* .QuestionsTable tr:first-child {
  background-color: lightblue;
} */

.SubQuestionTitle {
  background-color: lightblue;
}

.SubQuestionTitle td strong {
  margin-right: 8px;
}
