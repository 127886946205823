@use '../../sass/main.scss' as *;

.HomePage {
  width: 100%;
  height: calc(100vh - 3rem);
  // height: 100%;
  background-color: #efeffa;
  text-align: center;
  overflow: hidden;
}

.Carousel {
  min-height: calc(100vh / 1.7) !important;
}

.ImageContainerStyle {
  margin: auto;
  margin-top: 2rem;
  // width: calc(100vw - 8em) !important;
  // height: calc(100vh - 8em);
  // color: '#fff';
  // background: #364d79;
}

.ImageStyle {
  object-fit: contain;
  width: 100%;
  height: calc(100vh / 1.8) !important;
  border-radius: 10px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5);
}

.TitleCardContainer {
  display: flex;
  justify-content: center;
}

.TitleCard {
  $width: 800px;
  $height: 260px;
  width: $width;
  // height: $height;
  background-color: transparent !important;
  border-radius: 10px;
  border-width: 0px;

  .BigTitle {
    font-family: 'Georama', sans-serif;
    font-weight: bold;
    font-size: 3.5em;
    color: #9b4d04;
    text-shadow: 3px 3px 3px white;
    margin-bottom: 0.5em;
  }
  .SubTitle1,
  .SubTitle2 {
    font-family: 'Oswald', sans-serif;
    color: #ac5c11;
    text-shadow: 3px 3px 3px white;

    font-weight: 500;
  }
  .SubTitle1 {
    margin-bottom: 0.2em;
    font-size: 2em;
  }
  .SubTitle2 {
    font-size: 1.8em;
  }
}
