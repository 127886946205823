.container {
  min-height: calc(100vh - 50px);
  max-width: 100vw;
  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 8px;
  overflow: auto;
}

.title {
  width: 100%;
  background-color: rgb(202, 237, 202);
  text-align: center;
  font-size: 1.3rem;
  display: block;
  height: 2.5rem;
  padding-top: 4px;
}

.contents {
  display: flex;
  flex-direction: row;

  .selectionPanel {
    width: 250px;
    // background-color: pink;
    margin-top: 10px;
    height: calc(100% - 100px);
    overflow: auto;
  }

  .displayPanel {
    flex: 1;
    min-width: 800px;
    .buttonContainer {
      position: relative;
      .innerButtonContainer {
        position: absolute;
        top: 20px;
        left: 15px;
        z-index: 5;
        .exportButton,
        .clearFilterButton,
        .syncButton,
        .summaryButton {
          border-radius: 4px;
          margin-right: 8px;
          border: 0;
        }
      }
    }

    .dataTable {
      margin-top: 6px;
      background-color: rgb(192, 230, 255);
    }
  }
}
