@use 'typography' as *;

h1,
h2,
h3,
h4,
h5,
h6,
p,
span {
  font-family: $sans-serif;
}
